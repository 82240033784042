import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import CategoryPill from '../components/category';
import Share from '../components/share';
import MailChimp from '../components/mailchimp';

import useScrollToBottom from '../hooks/useScrollPosition';
import './blog-post.css';

export default function BlogPostTemplate({ data }) {
  const handleClose = () => {
    setVisible(false);
  };

  const [isVisible, setVisible] = React.useState(false);
  const [count, setcount] = React.useState(0);
  const [ref, isBottom] = useScrollToBottom();

  React.useEffect(() => {
    if (isBottom && count === 0) {
      setVisible(true);
      setcount(count + 1);
      window.gtag('event', `newsletter_modal_visible`);
    }
  }, [isBottom, isVisible]);

  const { siteUrl } = data.site.siteMetadata;
  const post = data.markdownRemark;
  const {
    title,
    author,
    date,
    categories,
    description,
    featuredImage,
  } = post.frontmatter;
  const { slug, modifiedTime, prettyModifiedTime } = data.markdownRemark.fields;
  const { timeToRead } = post;
  const node = {
    first_publication_date: date,
    last_publication_date: modifiedTime,
    categories,
    wordCount: post.wordCount.words,
  };

  return (
    <Layout>
      <SEO
        title={title}
        desc={description}
        banner={
          featuredImage?.childImageSharp.gatsbyImageData.images.fallback.src
        }
        article={true}
        node={node}
        pathname={slug}
      />

      <article>
        <div className="pt-6">
          <h1 className="mb-6">{title}</h1>
          <GatsbyImage
            image={featuredImage?.childImageSharp.gatsbyImageData}
            alt={title}
          />
          <p className="text-sm text-gray-600 mt-6">
            <span className="text-gray-900 leading-none">{author}</span>
            <span className="mx-1">・</span>
            <span>
              <time title={date} dateTime={new Date(date).toISOString()}>
                {date}
              </time>
            </span>
            <span className="mx-1">・</span>
            <span>{timeToRead} min read</span>
            <span className="mx-1">・</span>
            <span>Last update: </span>
            <span>
              <time title={modifiedTime} dateTime={modifiedTime}>
                {prettyModifiedTime}
              </time>
            </span>
          </p>
          <div className="mt-6">
            {categories.map(category => (
              <CategoryPill category={category} />
            ))}
          </div>
          <Share url={`${siteUrl}${slug}`} title={title} />
          <div
            className="post-body text-justify"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
      </article>
      <div ref={ref}>
        <nav>
          <h2 className="mt-12 mb-6">Related Posts</h2>
          <ul>
            {data.relatedPosts.nodes.map(
              ({ id, timeToRead, fields, frontmatter }) => {
                const { slug } = fields;
                const { title, featuredImage, author, date } = frontmatter;
                return (
                  <li key={id} className="pb-4 mb-2 border-b">
                    <Link to={slug}>
                      <div className="flex items-center justify-center md:justify-start">
                        <GatsbyImage
                          image={featuredImage?.childImageSharp.gatsbyImageData}
                          alt={title}
                          className="mr-6 md:mr-12"
                        />
                        <div className="w-3/4 md:w-1/2">
                          <p>{title}</p>
                          <p className="text-sm text-gray-600">
                            <span className="text-gray-900 leading-none">
                              {author}
                            </span>
                            <span className="mx-1">・</span>
                            <span>
                              <time
                                title={date}
                                dateTime={new Date(date).toISOString()}
                              >
                                {date}
                              </time>
                            </span>
                            <span className="mx-1">・</span>
                            <span>{timeToRead} min read</span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              }
            )}
          </ul>
        </nav>
      </div>

      <div
        className={`fixed bottom-0 md:bottom-4 right-0 md:right-4 bg-white p-6 md:rounded w-full md:w-1/2 xl:w-1/4 text-center border md:shadow modal ${
          isVisible ? 'is-visible' : ''
        }`}
      >
        <div
          className="absolute top-0 right-0 mt-4 mr-4 cursor-pointer z-50"
          role="button"
          onClick={handleClose}
        >
          <svg
            className="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
        <MailChimp />
      </div>
    </Layout>
  );
}

export const postQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        date(formatString: "D MMMM Y")
        author
        categories
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      fields {
        slug
        modifiedTime
        prettyModifiedTime: modifiedTime(formatString: "D MMMM Y - hh:mm")
      }
      html
      timeToRead
      wordCount {
        words
      }
    }
    relatedPosts: allMarkdownRemark(
      filter: { fields: { slug: { ne: $slug } } }
      limit: 3
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          author
          date(formatString: "D MMMM Y")
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 128, height: 128)
            }
          }
        }
        id
        timeToRead
      }
    }
  }
`;
