import * as React from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

const Share = ({ url, title }) => {
  const size = 32;
  return (
    <div className="py-6">
      <p className="mb-2">
        <strong>Share this post</strong>
      </p>
      <TwitterShareButton
        title={title}
        url={url}
        via="nhswd"
        hashtags={[
          'javascript',
          'webcomponents',
          'web',
          'webdev',
          'webdevelopment',
          'programming',
          'coding',
          'frontend',
          'DevCommunity',
          'html',
          'css',
          'angular',
          'react',
          'vuejs',
          'nextjs',
        ]}
        className="mr-2"
      >
        <TwitterIcon round={true} size={size} />
      </TwitterShareButton>
      <FacebookShareButton title={title} url={url} className="mr-2">
        <FacebookIcon round={true} size={size} />
      </FacebookShareButton>
      <LinkedinShareButton title={title} url={url} className="mr-2">
        <LinkedinIcon round={true} size={size} />
      </LinkedinShareButton>
      <RedditShareButton title={title} url={url} className="mr-2">
        <RedditIcon round={true} size={size} />
      </RedditShareButton>
    </div>
  );
};

export default Share;
